<template>
  <Wrapper>
    <div class="service">

      <DetailBanner titleType='2' title=""></DetailBanner>

      <water-title Chinese="供求信息" English="SUPPLY AND DEMAND"></water-title>

      <div class="w" style="margin-bottom: 30px;">
        <div class="title_wrap">
          <div class="title">
            <div class="txt">
              供应详情
            </div>
            <div class="goback" @click="goback">
              <p>返回</p>
              <img src="@/assets/imgs/detail-back1.png" />
            </div>
          </div>
          <div class="line_wrap">
            <div class="innerline"/>
          </div>
        </div>
        <div class="detail_wrap" style="margin-top: 40px;">
          <div class="left">
            <div class="img_wrap">
              <!-- <img :src="baseUrl + supplyDetail.picsurl" alt=""> -->
              <el-image
                      style="width: 100%;height: 100%;"
                      :fit="'cover'"
                      :src="baseUrl + supplyDetail.picsurl"
                      lazy
              ></el-image>
            </div>
            <div class="desc_wrap">
              <div class="title">
                供应 {{supplyDetail.name}}
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">规格:</div>
                  <div class="txt">{{supplyDetail.spec}}</div>
                </div>
                <div class="item">
                  <div class="title_left">数量:</div>
                  <div class="txt" style="color: #FF3750;">{{supplyDetail.stockcount}}</div>
                </div>
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">产地:</div>
                  <div class="txt">{{supplyDetail.addr}}</div>
                </div>
                <div class="item">
                  <div class="title_left">价格:</div>
                  <div class="txt">{{supplyDetail.price}}</div>
                </div>
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">有效期:</div>
                  <div class="txt" style="color: #FF3750;">剩余{{supplyDetail.deadline}}天</div>
                </div>
                <div class="item">
                  <div class="title_left">发布时间:</div>
                  <div class="txt">{{supplyDetail.publishdatetime}}</div>
                </div>
              </div>
              <!-- <div class="middle_content">
                <div class="item" style="width: 100% !important;">
                  <div class="title_left">货源所在地:</div>
                  <div class="txt">---------</div>
                </div>
              </div> -->
              <div style="position: absolute;bottom: 30px; left: 30px;">
                <el-button size="small"  class="warnning-btn" @click="getComment()">询价留言</el-button>
                <el-button size="small"  class="search-btn" @click="toMyShop()">进入店铺</el-button>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title_wrap">
              <div class="title">
                <div class="txt" style="padding-bottom: 8x;">
                  店主信息
                </div>
              </div>
              <div class="line_wrap">
                <div class="innerline" style="width: 50% !important;"/>
              </div>
              <div class="message_item_wrap">
                <div class="arrow">></div>
                <div class="question">联系人:</div>
                <div class="answer">{{ companyDetail.company_name }}</div>
              </div>
              <div class="message_item_wrap">
                <div class="arrow">></div>
                <div class="question">电话:</div>
                <div class="answer">{{companyDetail.contact_tel}}</div>
              </div>
              <div class="message_item_wrap">
                <div class="arrow">></div>
                <div class="question">实名认证:</div>
                <div class="answer" style="color: white; background-color: #7ACA5A;border-radius: 5px;">已认证</div>
              </div>
              <div class="message_item_wrap">
                <div class="arrow">></div>
                <div class="question">浏览量:</div>
                <div class="answer">{{companyDetail.pageview}}次</div>
              </div>
              <div class="message_item_wrap">
                <div class="arrow">></div>
                <div class="question">创店时间:</div>
                <div class="answer">{{companyDetail.createDate}}</div>
              </div>
            </div>
          </div>
        </div>

        <el-dialog
                title="询价留言"
                :visible.sync="dialogFormVisible"
                :show-close="false"
        >
          <el-form :model="form" :rules="rules" ref="form" label-width="120px">
            <el-row>
              <el-col :span="22">
                <el-form-item label="标题" placeholder="请输入留言标题" prop="title">
                  <el-input
                          class="dialog-input"
                          v-model="form.title"
                          autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="内容"  placeholder="请输入留言内容" prop="content">
                  <el-input
                          v-model="form.content"
                          type="textarea"
                          :rows="5"
                          autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="联系方式" placeholder="请输入联系方式"  prop="phoneNum">
                  <el-input
                          class="dialog-input"
                          v-model="form.phoneNum"
                          autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button class="footer-sub" @click="submitComment" :disabled="isDisable">确认</el-button>
            <el-button class="footer-del" @click="dialogFormVisible = false">取消</el-button>
          </div>
        </el-dialog>


        <div class="title_wrap" style="margin-top: 30px;">
          <div class="title">
            <div class="txt">
              商品描述
            </div>
            <div class="arrow">
              >
            </div>
          </div>
          <div class="line_wrap">
            <div class="innerline"/>
          </div>
        </div>
        <!-- <div class="big_img">

        </div> -->
        <!-- <div class="big_desc">
          {{ supplyDetail.descriptions }}
        </div> -->
        <div class="small_desc">
          {{ supplyDetail.descriptions }}
        </div>
        <!-- <div class="big_img">

        </div>
        <div class="big_desc">
          量大从优，质量有保障
        </div>
        <div class="small_desc">
          冬虫夏草，中药名。为肉座菌目麦角菌科虫草属真菌冬虫夏草菌Cordyceps sinensis( BerK.)Sacc.寄生在蝙
        </div> -->
        <div class="title_wrap" style="margin-top: 30px;" v-if="detailType == 1">
          <div class="title">
            <div class="txt">
              质检报告
            </div>
            <div class="arrow">
              >
            </div>
          </div>
          <div class="line_wrap">
            <div class="innerline"/>
          </div>
        </div>
        <div class="qualityImgWrap" style="margin-bottom: 30px;" v-if="detailType == 1">
          <div class="qualityImg">
            <el-image
                    style="width: 100%;height: 100%;"
                    :fit="'cover'"
                    :src="baseUrl + supplyDetail.zj"
                    lazy
            ></el-image>
          </div>
          <!-- <div class="qualityImg">

          </div>
          <div class="qualityImg">

          </div> -->
        </div>
        <!-- <div class="title_wrap" style="margin-top: 30px;">
          <div class="title">
            <div class="txt">
              好货推荐
            </div>
            <div class="arrow">
              >
            </div>
          </div>
          <div class="line_wrap">
            <div class="innerline"/>
          </div>
        </div>
        <div class="recommend_wrap">
          <div class="recommend_item" v-for="(item,index) in list1" :key="index">
            <div class="img_wrap">

              </div>
            <div class="desc_wrap">
              <div class="title">
                供应 【冬虫夏草】
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">规格:</div>
                  <div class="txt">统</div>
                </div>

                <div class="item">
                  <div class="title_left">价格:</div>
                  <div class="txt">20000元</div>
                </div>
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">数量:</div>
                  <div class="txt">1吨</div>
                </div>
                <div class="item">
                  <div class="title_left">产地:</div>
                  <div class="txt">内蒙</div>
                </div>
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">所在地:</div>
                  <div class="txt" >安徽省</div>
                </div>
                <div class="item">
                  <div class="title_left">发布时间:</div>
                  <div class="txt">2023-08-08</div>
                </div>
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">有效期:</div>
                  <div class="txt">剩余2天</div>
                </div>
                <div class="item">
                  <div class="title_left">实名认证:</div>
                  <div class="txt">已认证</div>
                </div>
              </div>
              <div class="middle_content">
                <div class="item">
                  <div class="title_left">联系人:</div>
                  <div class="txt">张三</div>
                </div>
                <div class="item">
                  <div class="title_left">手机:</div>
                  <div class="txt">18888888888</div>
                </div>
              </div>
              <div class="middle_content">
                <div class="item" style="width: 100% !important;">
                  <div class="title_left">地址:</div>
                  <div class="txt">---------</div>
                </div>
              </div>
              <div style="position: absolute;bottom: 30px; left: 30px;">
                <el-button size="small"  class="search-btn" @click="goToDetail()">查看详情</el-button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </Wrapper>
</template>

<script>
  import DetailBanner from '../sections/DetailBanner.vue'
  import WaterTitle from '@/components/WaterTitle.vue'
  import Wrapper from '@/components/Wrapper.vue'
  import { INFO_URL } from '@/config/globalConfig'

  import { messageSave } from "@/api/modules/gq";
  // import FromRules from '@/utils/fromRules'
  import { saveView } from "@/api/modules/index";


  export default {
    mixins: [],
    components: {
      Wrapper,
      DetailBanner,
      WaterTitle,
    },
    data() {
      return {
        baseUrl: INFO_URL,
        actIndex: 0,
        info: {},
        parlourForm: {},
        searchParams:{

        },form: {
          title: '',
          content: '',
          phoneNum: '',
        },
        defaultActId:'0',
        leftList:[{},{},{},{}],
        drugList:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
        total:1,
        list1:[{},{},{},{}],
        supplyDetail:{},
        companyDetail:{},
        detailType:1,
        isDisable: false, //禁用确认按钮
        dialogFormVisible: false,
      }
    },
    created() {

      const id = this.$route.params.id
      this.detailType = this.$route.params.type
      let formData = new FormData()
      formData.append('id',id)
      if (this.detailType == 1) {
        this.$axios.getSupplyInfo(formData).then( res => {
          debugger
          this.info = res.formData
          this.supplyDetail = res.data || {}
          let formData1 = new FormData()
          formData1.append('username',this.supplyDetail.contacttel)
          this.$axios.getYshUserById(formData1).then( res1 => {
            this.companyDetail = res1.data || {}
          })
        })
      }else {
        this.$axios.getPurchaseInfo(formData).then( res => {
          debugger
          this.info = res.formData
          this.supplyDetail = res.data || {}
          this.supplyDetail.picsurl = this.supplyDetail.pics
          this.supplyDetail.stockcount = this.supplyDetail.buycount
          let formData1 = new FormData()
          formData1.append('username',this.supplyDetail.contacttel)
          this.$axios.getYshUserById(formData1).then( res1 => {
            this.companyDetail = res1.data || {}
          })
        })
      }
    },
    methods: {
      goback(){
        this.$router.go(-1)
      },submitComment () {
        const query = {
          title: this.form.title,
          content: this.form.content,
          phoneNum: this.form.phoneNum,
          pId: this.$route.query.id,
          type: this.$route.query.type
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            messageSave(query).then(res => {
              if (res.code == 200) {
                this.dialogFormVisible = false
                this.$message.success('留言成功')
                this.isDisable = true
              }
            })
          }
        })
      },
      getComment () {
        this.dialogFormVisible = true
        this.isDisable = false
      },
     /* getInfo () {
        if (this.$route.query.type == 1) {
          getPurchaseInfo({ id: this.$route.query.id }).then(res => {
            this.info = res.data
            this.getDetail()
            if (this.info.pics) {
              this.info.pics = this.info.pics.split(',')
            }
            getById({ username: res.data.publisher_username }).then(res => {
              if (res.code == 200) {
                this.parlourForm = res.data
              }
            })
          })
        } else {
          getSupplyInfo({ id: this.$route.query.id }).then(res => {
            this.info = res.data
            this.getDetail()
            if (this.info.picsurl) {
              this.info.picsurl = this.info.picsurl.split(',')
            }
            console.log(this.info, 222)
            getById({ username: res.data.publisher_username }).then(res => {
              if (res.code == 200) {
                this.parlourForm = res.data
              }
            })
          })
        }
      },*/
      toMyShop () {
        /*if (this.supplyDetail.id) {
          saveView({
            viewType: 1,
            relateId: this.supplyDetail.id,
          }).then((res) => { });
        }*/

        this.$router.push({
          name: "myShop",
          query: {
            username: this.supplyDetail.contacttel,
            id: this.$route.query.id,
            type: this.$route.query.type,
            purchaseType: this.$route.query.purchaseType,
          },
        });

      },
    }
  }
</script>

<style lang='scss' scoped>
  .service {
    padding: 50px 0px 0px 0px;
  }
  .title_wrap{

    .title{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .txt{
        font-size: 22px;
        color: #181F2D;
        padding: 10px 0px;
      }
      .arrow{
        font-size: 20px;
        color: #A4A9B4;
        font-weight: bold;
      }
    }
    .line_wrap{
      width: 100%;
      height: 2px;
      background-color: #CED5E1;
      .innerline{
        width: 7%;
        height: 2px;
        background-color: #315CCF;
      }
    }
  }
  .goback {
    position: relative;
    cursor: pointer;
    p {
      position: absolute;
      left: 85px;
      top: 0;
      font-size: 14px;
      color: #fff;
      font-family: MicrosoftYaHei;
      line-height: 34px;
    }
  }
  .detail_wrap{
    display: flex;
    .left{
      flex: 1;
      height: 282px;
      display: flex;
      .img_wrap{
        width: 255px;
        height: 100%;
        background: #656766;
      }
      .desc_wrap{
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        position: relative;
        .title{
          font-size: 22px;
          color: #2F3542;
        }
        .middle_content{
          width: 100%;
          display: flex;
          padding-top: 25px;
          .item{
            width: 50%;
            font-size: 16px;
            display: flex;

            .title_left{
              white-space: nowrap;
              color: #0D162A;
            }
            .txt{
              white-space: nowrap;
              color:#626F90
            }
            .identify{
              color: white;
              background-color: rgb(122, 202, 90);
              border-radius: 5px;
              padding: 5px;
              line-height: 10px;
              margin-top: -3px;
            }
          }
        }
      }
    }
    .right{
      width: 362px;
    }
  }
  .search-btn{
    background-color: #315CCF !important;
    color:#fff
  }
  .warnning-btn{
    background-color: #FAAD14 !important;
    color:#fff
  }
  .message_item_wrap{
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: #E9ECF2 solid 1px;
    .arrow{
      padding-left: 5px;
      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
    }
    .question{
      font-size: 16px;
      width: 30%;
      color: #0D162A;
      padding-left: 12px;
    }
    .answer{
      font-size: 16px;
      //width: 66%;
      color: #536387;
    }
  }
  .big_img{
    width: 870px;
    height: 450px;
    background-color: #525453;
    margin-top: 22px;
  }
  .big_desc{
    margin-top: 30px;
    font-size: 24px;
    font-weight: Bold;
    color: #2F3542;
  }
  .small_desc{
    margin-top: 10px;
    font-size: 14px;
    color: #536387;
  }
  .qualityImgWrap{
    display: flex;
    justify-content: space-between;
    margin-top: 23px;
    .qualityImg{
      width: 390.62px;
      height: 255.53px;
      background-color: #525453;
    }
  }
  .recommend_wrap{
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .recommend_item{
      width: 50%;
      height: 261px;
      display: flex;
      margin-bottom: 24px;
      .img_wrap{
        width: 217px;
        height: 261px;
        background-color: #525453;
      }
      .desc_wrap{
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        position: relative;
        .title{
          font-size: 22px;
          color: #2F3542;
        }

        .el-dialog {
          border-radius: 10px;
        }
        .el-dialog__header {
          text-align: center;
          height: 76px;
          background: #1b86fb;
          padding: 0;
          line-height: 76px;
          margin: 0 auto;
        }
        .el-dialog__body {
          background: #f9f8f8;
        }
        .el-dialog__footer {
          text-align: center;
          background: #f9f8f8;
        }


        .footer-sub {
          width: 144px;
          height: 60px;
          background: #1b86fb;
          border-radius: 6px;
          color: #ffffff;
          border: 2px solid #1b86fb;
          margin-right: 185px;
        }

        .el-dialog__title {
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .footer-del {
          width: 144px;
          height: 60px;
          border-radius: 6px;
          border: 2px solid #1b86fb;
          color: #1b86fb;
        }

        .middle_content{
          width: 100%;
          display: flex;
          padding-top: 10px;
          .item{
            width: 50%;
            font-size: 16px;
            display: flex;

            .title_left{
              white-space: nowrap;
              color: #0D162A;
            }
            .txt{
              white-space: nowrap;
              color:#626F90
            }
            .identify{
              color: white;
              background-color: rgb(122, 202, 90);
              border-radius: 5px;
              padding: 5px;
              line-height: 10px;
              margin-top: -3px;
            }
          }
        }
      }

    }
  }
</style>